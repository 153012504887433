import AboutContent from "@blackwolf/menu/components/about/AboutContent";
import AppLayout from "@blackwolf/menu/components/layouts/AppLayout";

export default function AboutPage() {
  return (
    <AppLayout>
      <AboutContent />
    </AppLayout>
  );
}
