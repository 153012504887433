const Colors = {
  PRIMARY: "#333333",
  PRIMARY_DARK: "#4F4F4F",
  PRIMARY_LIGHT: "#616161",
  SECONDARY: "#EB5757",
  SUCCESS: "#4CAF50",
  ERROR: "#F44336",
  WHITE: "#FFFFFF",
  MAIN_BACKGROUND: "#000",
  SHADOW: "rgb(0 0 0 / 13%)",
  GREEN: "#a6bd8e",
  ORANGE: "#fe9504",
  RED: "#ff4e1d",
  PURPLE: "#b292e5",
  BLUE: "#0804F9",
  LINK_UNDERLINE: "#42b983",
  BORDER_RIGHT_BORDER: "#808080",
  INFO_BOX_BACKGROUND: "#c0c0c0",
  INFO_BOX_HOVER_BACKGROUND: "#a9a9a9",
};

export default Colors;
