import { Box, Stack } from "@mui/material";
import CustomBackButton from "../form/CustomBackButton";
import ReservationButton from "../form/ReservationButton";

export default function PageFooter() {
  return (
    <Stack
      padding="2rem 0"
      direction="row"
      alignItems="flex-end"
      justifyContent={{ lg: "flex-end", xs: "space-between" }}
      flexWrap="wrap"
    >
      <Box display={{ xs: "block", lg: "none" }}>
        <ReservationButton />
      </Box>
      <CustomBackButton />
    </Stack>
  );
}
