import AboutPageType from "@blackwolf/common/dto/strapi/data/collections/about/AboutPageType";
import StrapiSingleItemResponse from "@blackwolf/common/dto/strapi/item/StrapiSingleItemResponse";
import GetItemsReducer from "@blackwolf/shared-frontend/store/getItems/GetItemsReducer";
import { GET_ABOUT_PAGE } from "./aboutPage.types";

const AboutPageReducer = GetItemsReducer<
  StrapiSingleItemResponse<AboutPageType> | undefined
>(GET_ABOUT_PAGE, undefined);

export default AboutPageReducer;
