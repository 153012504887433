const ROOT = "/";
const MENU = "/menu";
const DRINKS = "/drinks";
const DETAILS = "/details";
const ABOUT = "/about";

const routes = {
  ROOT,
  MENU,
  DRINKS,
  DETAILS,
  ABOUT,
};

export default routes;
