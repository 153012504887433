import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import { Button, ButtonProps, Link, styled } from "@mui/material";

const ReservationLink =
  "https://www.opentable.com/r/black-wolf-supper-club-reservations-eugene?restref=1089562&lang=en-US&ot_source=Restaurant%20website";

const StyledButton = styled(Button)(({ theme }) => ({
  color: Colors.WHITE,
  padding: theme.spacing(1.75, 4),
  "&.MuiButton-root, &.MuiButton-root:hover": {
    outline: `6px ridge ${Colors.BLUE}`,
    outlineOffset: "-6px",
  },
  "&:hover": {
    backgroundColor: Colors.BLUE,
  },
}));

export default function ReservationButton(props: ButtonProps) {
  return (
    <Link href={ReservationLink} target="_blank">
      <StyledButton variant="outlined" {...props}>
        RESERVATIONS
      </StyledButton>
    </Link>
  );
}
