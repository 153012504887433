import InfoItemComponentData from "@blackwolf/common/dto/strapi/data/components/ui/InfoItemComponentData";
import StrapiUtil from "@blackwolf/menu/utils/StrapiUtil";
import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import { Box, Link, LinkProps, Stack, styled, Typography } from "@mui/material";
import Markdown from "react-markdown";

const InfoCardImage = styled("img")({
  width: 40,
  height: 40,
});

const InfoCardContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 16,
  background: Colors.INFO_BOX_BACKGROUND,
  padding: 6,
  borderTop: `5px ridge ${Colors.WHITE}`,
  borderLeft: `5px ridge ${Colors.WHITE}`,
  borderRight: `5px ridge ${Colors.BORDER_RIGHT_BORDER}`,
  borderBottom: `5px ridge ${Colors.BORDER_RIGHT_BORDER}`,
  "&:hover": {
    backgroundColor: Colors.INFO_BOX_HOVER_BACKGROUND,
  },
});

const StyledLink = styled(Link)({
  color: "inherit",
  "&, &:hover": {
    textDecoration: "underline",
    textDecorationColor: Colors.LINK_UNDERLINE,
  },
});

interface OptionalLinkProps {
  href?: string | null;
}

function OptionalLink({
  children,
  href,
  ...props
}: OptionalLinkProps & Omit<LinkProps, "href">) {
  if (href) {
    return (
      <StyledLink href={href} {...props}>
        {children}
      </StyledLink>
    );
  }
  return <>{children}</>;
}

interface InfoCardProps {
  data: InfoItemComponentData;
}

export default function InfoCard({ data }: InfoCardProps) {
  const url = StrapiUtil.getMediaUrl(data.icon);
  return (
    <Box>
      <Typography variant="h2">{data.name}</Typography>
      <OptionalLink href={data.link}>
        <InfoCardContainer alignItems="center">
          {url && <InfoCardImage src={url} alt="hours" />}
          <Stack direction="column" spacing={2}>
            <Markdown>{data.content}</Markdown>
          </Stack>
        </InfoCardContainer>
      </OptionalLink>
    </Box>
  );
}
