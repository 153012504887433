import MenuType from "@blackwolf/common/dto/strapi/data/collections/menu/MenuType";
import StrapiItemResponse from "../../../common/src/dto/strapi/item/StrapiItemResponse";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class MenuApi {
  public static getMenuItem = async (name: string) => {
    try {
      const menu = await StoreInstance.api().get<StrapiItemResponse<MenuType>>(
        `/api/menus?filters[key][$eqi]=${name}&populate[0]=sections.items`
      );
      return menu.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get all Menus");
    }
  };
}
