import AboutApi from "@blackwolf/shared-frontend/api/AboutApi";
import GetItemsAction from "@blackwolf/shared-frontend/store/getItems/GetItemsAction";
import { RootState } from "..";
import { GET_ABOUT_PAGE } from "./aboutPage.types";

export const GetAboutPage = GetItemsAction(
  GET_ABOUT_PAGE,
  AboutApi.getPageInfo,
  (state: RootState) => state.aboutPage
);
