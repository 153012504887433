import MenuType from "@blackwolf/common/dto/strapi/data/collections/menu/MenuType";
import StrapiItemResponse from "@blackwolf/common/dto/strapi/item/StrapiItemResponse";
import AppLayout from "@blackwolf/menu/components/layouts/AppLayout";
import MenuView from "@blackwolf/menu/components/menu/MenuView";
import MenuApi from "@blackwolf/shared-frontend/api/MenuApi";
import { Labels } from "@blackwolf/shared-frontend/components/labels/AllLabels";
import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

const menuName = "food";

export default function FoodMenuPage() {
  const [menu, setMenu] = useState<StrapiItemResponse<MenuType>>();
  const fetchForm = useCallback(() => {
    MenuApi.getMenuItem(menuName).then(setMenu).catch(console.error);
  }, []);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  return (
    <AppLayout>
      <MenuView menu={menu?.data?.[0]}>
        <Typography variant="body1">{Labels.Menu_disclaimer}</Typography>
      </MenuView>
    </AppLayout>
  );
}
