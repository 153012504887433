import ItemComponentData from "@blackwolf/common/dto/strapi/data/components/ui/ItemComponentData";
import Fonts from "@blackwolf/shared-frontend/utils/styles/Fonts";
import { Box, Grid, Typography } from "@mui/material";

interface ListItemProps {
  itemValue: ItemComponentData;
}

export default function ListItem({ itemValue }: ListItemProps) {
  return (
    <Box marginY={1.5}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h5">{itemValue.name}</Typography>
          <Typography variant="body1" fontFamily={Fonts.Oswald}>
            {itemValue.description}
          </Typography>
        </Grid>

        <Grid item xs={3} textAlign="end">
          <Typography variant="h5">{itemValue.amount}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
