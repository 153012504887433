import ScrollToTop from "@blackwolf/shared-frontend/components/ScrollToTop";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import DashboardScreen from "../pages/Dashboard/DashboardScreen";
import AboutPage from "../pages/about/AboutPage";
import DrinksPage from "../pages/drink/DrinksPage";
import InfoPage from "../pages/info/InfoPage";
import FoodMenuPage from "../pages/menu/MenuPage";
import routes from "./routes";

export default function AppRouter() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={routes.ROOT} element={<DashboardScreen />} />
        <Route path={routes.MENU} element={<FoodMenuPage />} />
        <Route path={routes.DRINKS} element={<DrinksPage />} />
        <Route path={routes.ABOUT} element={<AboutPage />} />
        <Route path={routes.DETAILS} element={<InfoPage />} />
        <Route path="*" element={<Navigate to={routes.ROOT} replace />} />
      </Routes>
    </Router>
  );
}
