import { selectInfoPage } from "@blackwolf/menu/store/infoPage/infoPage.selector";
import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import PageFooter from "../layouts/PageFooter";
import InfoCard from "./InfoCard";

export default function InfoCards() {
  const infoPage = useSelector(selectInfoPage);

  if (!infoPage) {
    return null;
  }

  return (
    <Box color={Colors.WHITE} height="100%">
      <Typography variant="h1">{infoPage.pageName}</Typography>

      <Stack direction="column" spacing={0.5} color={Colors.WHITE}>
        {infoPage.items.map((item) => (
          <InfoCard data={item} key={item.id} />
        ))}
      </Stack>
      <PageFooter />
    </Box>
  );
}
