import setReactApi from "@blackwolf/shared-frontend/components/ReactApi";
import ReactToastComponent, {
  setReactToast,
} from "@blackwolf/shared-frontend/components/ReactToastComponent";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import AppRouter from "./routes/AppRouter";
import { store } from "./store";
import DefaultTheme from "./utils/styles/DefaultTheme";

setReactApi();
setReactToast();

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <AppRouter />
        <ReactToastComponent />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
