import { selectAboutPage } from "@blackwolf/menu/store/aboutPage/aboutPage.selector";
import StrapiUtil from "@blackwolf/menu/utils/StrapiUtil";
import { Box, Stack, styled, Typography } from "@mui/material";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import PageFooter from "../layouts/PageFooter";

const StyledImage = styled("img")({
  height: 250,
  width: 250,
});

export default function AboutContent() {
  const aboutPage = useSelector(selectAboutPage);

  if (!aboutPage) {
    return null;
  }
  const url = StrapiUtil.getMediaUrl(aboutPage.image);

  return (
    <Stack p={0} spacing={1} marginY="auto">
      {url && (
        <StyledImage src={url} alt={aboutPage.image.data?.attributes.name} />
      )}
      <Typography variant="h1" color="white">
        {aboutPage.title}
      </Typography>

      <Box>
        <Stack direction="column" spacing={2}>
          <Markdown>{aboutPage.description}</Markdown>
        </Stack>
      </Box>
      <PageFooter />
    </Stack>
  );
}
