import axios from "axios";

const Api = (apiUrl: string) => () => {
  const request = axios.create({
    baseURL: apiUrl,
    responseType: "json",
    withCredentials: true,
  });

  return request;
};

export default Api;
