import { Box, keyframes, styled } from "@mui/material";

import ReservationButton from "@blackwolf/menu/components/form/ReservationButton";
import FullWidthPage from "@blackwolf/menu/components/layouts/FullWidthPage";
import routes from "@blackwolf/menu/routes/routes";
import CenteredBox from "@blackwolf/shared-frontend/components/CenteredBox";
import Logo from "@blackwolf/shared-frontend/src/assets/blackwolflogo-white.png";
import Info from "@blackwolf/shared-frontend/src/assets/chefskiss.gif";
import Drinks from "@blackwolf/shared-frontend/src/assets/cocktail-light.gif";
import About from "@blackwolf/shared-frontend/src/assets/comeonin.gif";
import Food from "@blackwolf/shared-frontend/src/assets/dinnerlunch.gif";
import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import DashboardItem from "./DashboardItem";

const spin = keyframes`
50% {
  transform: rotateY(180deg);
}
`;

const StyledImg = styled("img")({
  display: "block",
  maxWidth: "100% !important",
  alignSelf: "center",
  animation: `${spin} 2s linear infinite`,
});

export default function DashboardScreen() {
  return (
    <FullWidthPage position="relative" justifyContent="space-between">
      <CenteredBox
        position="absolute"
        height="100%"
        width="100%"
        spacing={3}
        top={0}
        left={0}
      >
        <StyledImg height="25%" src={Logo} alt="Logo" />
        <ReservationButton />
      </CenteredBox>

      <Box width="100%" maxHeight="33vh" display="flex">
        <DashboardItem
          image={Food}
          borderColor={Colors.GREEN}
          url={routes.MENU}
          outlineStyle="ridge"
          name="FOOD"
          hoverColor={Colors.ORANGE}
        />

        <DashboardItem
          image={Drinks}
          borderColor={Colors.ORANGE}
          url={routes.DRINKS}
          backgroundSize="70%"
          outlineStyle="outset"
          name="DRINKS"
          hoverColor={Colors.RED}
        />
      </Box>

      <Box width="100%" maxHeight="33vh" display="flex">
        <DashboardItem
          url={routes.DETAILS}
          image={About}
          borderColor={Colors.RED}
          backgroundSize="70%"
          outlineStyle="inset"
          name="INFO"
          hoverColor={Colors.PURPLE}
        />

        <DashboardItem
          url={routes.ABOUT}
          image={Info}
          borderColor={Colors.PURPLE}
          outlineStyle="groove"
          name="THE CHEF"
          hoverColor={Colors.GREEN}
        />
      </Box>
    </FullWidthPage>
  );
}
