import SectionComponentData from "@blackwolf/common/dto/strapi/data/components/ui/SectionComponentData";
import { Box, Typography } from "@mui/material";
import ListItem from "./ListItem";
interface MenuListProps {
  itemList: SectionComponentData[];
}

export default function MenuList({ itemList }: MenuListProps) {
  return (
    <>
      {itemList.map((item, index) => (
        <Box key={index}>
          <Typography variant="h2" mb={1}>
            {item.name}
          </Typography>
          {item.items.map((value, index) => (
            <ListItem key={index} itemValue={value} />
          ))}
        </Box>
      ))}
    </>
  );
}
