import StrapiNullableSingleItemResponse from "@blackwolf/common/dto/strapi/item/StrapiNullableSingleItemResponse";
import StrapiMediaAttributes from "@blackwolf/common/dto/strapi/list/StrapiMediaAttributes";
import EnvUtils from "@blackwolf/shared-frontend/utils/EnvUtils";

export default class StrapiUtil {
  static getMediaUrl = (
    file: StrapiNullableSingleItemResponse<StrapiMediaAttributes>
  ) => {
    const url = file.data?.attributes.url;
    if (!url) {
      return undefined;
    }
    return EnvUtils.apiUrl + url;
  };
}
