import routes from "@blackwolf/menu/routes/routes";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const borderStyle = (route: string) => {
  switch (route) {
    case routes.MENU:
      return "ridge";
    case routes.DRINKS:
      return "outset";
    case routes.DETAILS:
      return "inset";
    case routes.ABOUT:
      return "groove";
  }
};
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "link",
})<{ link: SidebarItemType }>(({ link }) => ({
  backgroundImage: `url(${link.image})`,
  backgroundRepeat: "no-repeat",
  outline: `12px ${borderStyle(link.url)} ${link.boxColor}`,
  outlineOffset: "-12px",
  backgroundPosition: "center",
  backgroundSize: `${link.name === "FOOD" ? "75%" : "fill"}`,
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  "&:hover": {
    width: "100%",
    height: "100%",
    transition: "all 0.5s ease",
    "& .overlay": {
      opacity: 0.9,
    },
  },
}));

const OverlayBox = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: 1,
  width: "100%",
  transition: "all 0.5s ease",
  opacity: 0,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledLink = styled(Link)({
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "200px",
  height: "20%",
  width: "100%",
});
export interface SidebarItemType {
  url: string;
  image: string;
  name: string;
  boxColor: string;
  hoverColor: string;
}
interface LinkProps {
  item: SidebarItemType;
}
export default function SidebarListItem({ item }: LinkProps) {
  return (
    <StyledLink to={item.url}>
      <StyledBox link={item}>
        <OverlayBox bgcolor={item.hoverColor} className="overlay">
          <Typography variant="h6">{item.name}</Typography>
        </OverlayBox>
      </StyledBox>
    </StyledLink>
  );
}
