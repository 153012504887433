import CenteredBox from "@blackwolf/shared-frontend/components/CenteredBox";
import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import { Box, styled, Typography } from "@mui/material";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)({
  display: "flex",
});

const StyledBox = styled(Box)({
  padding: 5,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "20vw",
  width: "20vw",
  minWidth: 100,
  minHeight: 100,
  maxWidth: 180,
  maxHeight: 180,
  backgroundColor: "black",
  position: "relative",
  zIndex: 1,
  "&:hover": {
    "& .overlay": {
      opacity: 0.9,
    },
  },
});
const OverlayBox = styled(Box)({
  border: "none",
  transition: ".5s ease",
  zIndex: 1,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  opacity: 0,
});

interface DashboardProps {
  image: string;
  borderColor: string;
  url: string;
  name: string;
  hoverColor: string;
  outlineStyle: CSSProperties["outlineStyle"];
  backgroundSize?: CSSProperties["backgroundSize"];
}

export default function DashboardItem({
  image,
  borderColor,
  url,
  outlineStyle,
  name,
  hoverColor,
  backgroundSize = "80%",
}: DashboardProps) {
  return (
    <CenteredBox width="50%" margin={1.5}>
      <StyledLink to={url}>
        <StyledBox
          marginY={3}
          sx={{
            outline: `8px ${outlineStyle} ${borderColor}`,
            outlineOffset: "-8px",
            backgroundImage: `url(${image})`,
            backgroundSize,
            backgroundRepeat: "no-repeat",
          }}
        >
          <OverlayBox bgcolor={hoverColor} className="overlay">
            <Typography color={Colors.WHITE} variant="h5">
              {name}
            </Typography>
          </OverlayBox>
        </StyledBox>
      </StyledLink>
    </CenteredBox>
  );
}
