import routes from "@blackwolf/menu/routes/routes";
import Back from "@blackwolf/shared-frontend//assets/backflame.gif";
import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)({
  display: "inline-flex",
});

const StyledButton = styled(Button)({
  padding: 0,
});

export default function CustomBackButton() {
  return (
    <StyledLink to={routes.ROOT}>
      <StyledButton>
        <img alt="back-button" src={Back} width={140} />
      </StyledButton>
    </StyledLink>
  );
}
