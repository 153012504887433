import InfoApi from "@blackwolf/shared-frontend/api/InfoApi";
import GetItemsAction from "@blackwolf/shared-frontend/store/getItems/GetItemsAction";
import { RootState } from "..";
import { GET_INFO_PAGE } from "./infoPage.types";

export const GetInfoPage = GetItemsAction(
  GET_INFO_PAGE,
  InfoApi.getInfoPage,
  (state: RootState) => state.infoPage
);
