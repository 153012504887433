import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import AboutPageReducer from "./aboutPage/aboutPage.reducer";
import InfoPageReducer from "./infoPage/infoPage.reducer";

export const store = configureStore({
  reducer: {
    infoPage: InfoPageReducer,
    aboutPage: AboutPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
