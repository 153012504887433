import AppLayout from "@blackwolf/menu/components/layouts/AppLayout";
import InfoCards from "../../components/info/InfoCards";

export default function InfoPage() {
  return (
    <AppLayout>
      <InfoCards />
    </AppLayout>
  );
}
