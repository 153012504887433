import InfoPageType from "@blackwolf/common/dto/strapi/data/collections/info/InfoPageType";
import StrapiSingleItemResponse from "@blackwolf/common/dto/strapi/item/StrapiSingleItemResponse";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class InfoApi {
  public static getInfoPage = async () => {
    try {
      const infoPage = await StoreInstance.api().get<
        StrapiSingleItemResponse<InfoPageType>
      >(`/api/info-page?populate[0]=items&populate[1]=items.icon`);
      return infoPage.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get Info Page Content");
    }
  };
}
