import MenuType from "@blackwolf/common/dto/strapi/data/collections/menu/MenuType";
import StrapiResponseData from "@blackwolf/common/dto/strapi/list/StrapiResponseData";
import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import PageFooter from "../layouts/PageFooter";
import MenuList from "./MenuList";

interface MenuViewProps {
  menu?: StrapiResponseData<MenuType>;
}

export default function MenuView({
  menu,
  children,
}: PropsWithChildren<MenuViewProps>) {
  if (!menu) {
    return null;
  }
  return (
    <Stack height="100%" maxWidth={600} marginX="auto">
      <Typography variant="h1">{menu.attributes.name}</Typography>
      <MenuList itemList={menu.attributes.sections} />
      {children}
      <PageFooter />
    </Stack>
  );
}
