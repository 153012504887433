import InfoPageType from "@blackwolf/common/dto/strapi/data/collections/info/InfoPageType";
import StrapiSingleItemResponse from "@blackwolf/common/dto/strapi/item/StrapiSingleItemResponse";
import GetItemsReducer from "@blackwolf/shared-frontend/store/getItems/GetItemsReducer";
import { GET_INFO_PAGE } from "./infoPage.types";

const InfoPageReducer = GetItemsReducer<
  StrapiSingleItemResponse<InfoPageType> | undefined
>(GET_INFO_PAGE, undefined);

export default InfoPageReducer;
