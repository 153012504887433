import routes from "@blackwolf/menu/routes/routes";
import Logo from "@blackwolf/shared-frontend/assets/blackwolflogo.png";
import CenteredBox from "@blackwolf/shared-frontend/components/CenteredBox";
import { Box, Button, Grid, keyframes, styled } from "@mui/material";
import { Link } from "react-router-dom";
import ReservationButton from "../../form/ReservationButton";
import SidebarListItem, { SidebarItemType } from "./SidebarListItem";

interface SidebarProps {
  items: SidebarItemType[];
}

const spin = keyframes`
50% {
  transform: rotateY(180deg);
}
`;

const StyledLink = styled(Link)({
  maxHeight: "200px",
  display: "block",
  margin: "auto",
  maxWidth: "100% !important",
  alignSelf: "center",
  animation: `${spin} 2s linear infinite`,
});

export default function Sidebar({ items }: SidebarProps) {
  return (
    <Grid container height="100%" px={5.5} spacing={3}>
      <Grid
        item
        xs={12}
        lg={6}
        display={{ xs: "none", lg: "block" }}
        height="100%"
      >
        <Box
          flexDirection="column"
          display="flex"
          height="100%"
          flexGrow={1}
          alignItems="center"
          justifyContent="space-around"
        >
          {items.map((item) => (
            <SidebarListItem key={item.url} item={item} />
          ))}
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        mt={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CenteredBox spacing={2}>
          <StyledLink to={routes.ROOT}>
            <Button
              sx={{ width: { xs: 100, lg: 200 }, height: { xs: 100, lg: 200 } }}
            >
              <img alt="logo-button" src={Logo} style={{ width: "inherit" }} />
            </Button>
          </StyledLink>
          <Box display={{ xs: "none", lg: "block" }}>
            <ReservationButton />
          </Box>
        </CenteredBox>
      </Grid>
    </Grid>
  );
}
