import { Box, BoxProps, Grid } from "@mui/material";

import routes from "@blackwolf/menu/routes/routes";
import { useAppDispatch } from "@blackwolf/menu/store";
import { GetAboutPage } from "@blackwolf/menu/store/aboutPage/aboutPage.actions";
import { GetInfoPage } from "@blackwolf/menu/store/infoPage/infoPage.actions";
import ChefsKiss from "@blackwolf/shared-frontend/assets/chefskiss.gif";
import CocktailLight from "@blackwolf/shared-frontend/assets/cocktail-light.gif";
import Info from "@blackwolf/shared-frontend/assets/comeonin.gif";
import Food from "@blackwolf/shared-frontend/assets/dinnerlunch.gif";
import Colors from "@blackwolf/shared-frontend/utils/styles/Colors";
import { useEffect } from "react";
import FullWidthPage from "./FullWidthPage";
import Sidebar from "./sidebar/Sidebar";
import { SidebarItemType } from "./sidebar/SidebarListItem";

const items: SidebarItemType[] = [
  {
    url: routes.MENU,
    image: Food,
    name: "FOOD",
    boxColor: Colors.GREEN,
    hoverColor: Colors.ORANGE,
  },
  {
    url: routes.DRINKS,
    image: CocktailLight,
    name: "DRINKS",
    boxColor: Colors.ORANGE,
    hoverColor: Colors.RED,
  },
  {
    url: routes.DETAILS,
    image: Info,
    name: "INFO",
    boxColor: Colors.RED,
    hoverColor: Colors.PURPLE,
  },
  {
    url: routes.ABOUT,
    image: ChefsKiss,
    name: "ABOUT",
    boxColor: Colors.PURPLE,
    hoverColor: Colors.GREEN,
  },
];

export default function AppLayout({ children, ...props }: BoxProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(GetInfoPage());
    void dispatch(GetAboutPage());
  }, [dispatch]);

  return (
    <FullWidthPage height="100%" overflow="scroll" {...props}>
      <Grid container flexGrow={{ lg: 1 }}>
        <Grid
          item
          xs={12}
          lg={6}
          maxHeight="100vh"
          position={{ lg: "sticky" }}
          top="0"
        >
          <Sidebar items={items} />
        </Grid>
        <Grid item xs={12} lg={6} alignSelf="center" overflow="auto">
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            padding={2}
            minHeight="100%"
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </FullWidthPage>
  );
}
