import AboutPageType from "@blackwolf/common/dto/strapi/data/collections/about/AboutPageType";
import StrapiSingleItemResponse from "@blackwolf/common/dto/strapi/item/StrapiSingleItemResponse";
import StoreInstance from "../store/StoreInstance";
import { handleApiError } from "../utils/ApiUtils";

export default class AboutApi {
  public static getPageInfo = async () => {
    try {
      const infoPage = await StoreInstance.api().get<
        StrapiSingleItemResponse<AboutPageType>
      >(`/api/about-page?populate[0]=image`);
      return infoPage.data;
    } catch (e) {
      throw handleApiError(e, "Failed to get Info Page Content");
    }
  };
}
